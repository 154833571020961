import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

const IconNumber = ({ size, className, icon, reverse, style }) => (
  <div
    style={{
      boxShadow: reverse ? "" : "3px 3px 7px 0 rgba(146,28,49,0.26)",
      height: `${size}px`,
      minHeight: `${size}px`,
      width: `${size}px`,
      minWidth: `${size}px`,
      background:
        icon === "aspera-white"
          ? "linear-gradient(135.28deg, #00B2EF 0%, #61D7FF 100%)"
          : reverse
            ? ""
            : "linear-gradient(-141deg, #FD2A51 26%, #FF4B6D 100%)",
      ...style
    }}
    className={`circle ${className} p1 flex items-center justify-center`}>
    <span css={css`
      color: #fff;
      font-size: 1.75em;
      font-weight: 900;
    `}>{icon}</span>
  </div>
)

IconNumber.defaultProps = {
  size: "48",
  className: "",
  reverse: false,
  style: {}
}

IconNumber.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  style: PropTypes.object
}

export default IconNumber
