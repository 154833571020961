import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { css } from "@emotion/core"
import Background from "@app-components/utils/background"
import Arrow from "@app-components/utils/arrow"
import Fade from "@app-components/transition/fade"
import IconNumber from "@app-components/utils/icon_number"

const Feature = ({ title, text, link, image, reverse, background, icon, button }) => (
  <Background
    tagName="section"
    className="bg-cover bg-no-repeat bg-center px2 md-px-3"
    type={background}>
    <Fade tagName="div" opacity up className="overflow-hidden">
      <React.Fragment>
        <div
          className="mx-auto max-width-3 flex flex-wrap items-center col-12 sm-col-10 md-col-8 lg-col-12"
          css={css`
            flex-direction: ${reverse ? "row-reverse" : ""};
          `}>
          <div className="col-12 lg-col-6 center sm-left-align">
            <IconNumber icon={icon} size="54" className="mb1 inline-flex mx-auto" />
            <h2>{title}</h2>
            <p className="mt1 max-width-3 large-p-tag">{text}</p>
            <div className="flex items-center mt2">
              {button && (
                <Link to={button.to} className="mr2">
                  <button tabIndex="-1" className={button.className}>
                    {button.label}
                  </button>
                </Link>
              )}
              {link && (
                <Link
                  className="flex items-center bold"
                  css={css`
                    font-size: 18px;
                  `}
                  to={link.to}>
                  <div className="red">{link.label}</div>
                  <Arrow className="block ml1" color="red" />
                </Link>
              )}
            </div>
          </div>
          <div className={`col-12 lg-col-6 mt3 lg-mt0 ${reverse ? `pr0 lg-pr3` : "pl0 lg-pl3"}`}>{image}</div>
        </div>
      </React.Fragment>
    </Fade>
  </Background>
)

Feature.defaultProps = {
  reverse: false,
  link: null,
  background: null,
  button: null
}

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string
  }),
  image: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
  background: PropTypes.string,
  icon: PropTypes.string.isRequired,
  button: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string
  })
}

export default Feature
