import React from "react"
import Layout from "@app-components/layout/"
import { css } from "@emotion/core"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import HeaderImage from "@page-components/index/media/header/"
import Background from "@app-components/utils/background"
import Step1Image from "@page-components/index/steps/step1"
import Step2Image from "@page-components/index/steps/step2"
import Step3Image from "@page-components/index/steps/step3"
import Step4Image from "@page-components/index/steps/step4"
import line from "@media/svg/squiggly_line.svg"

const Line = ({ reversed = false }) => {
  return (
    <div className="mx-auto max-width-3 mb1" css={css`
      padding-left: 22px;
      padding-right: 22px;


      @media (max-width: 639px) {
        text-align: right;
      }

      // @media (max-width: 54em) {
      //   text-align:  ${reversed ? 'left' : 'right'};
      // }
     
    `}>
      <img
        alt="Close"
        src={line}
        css={css`
          width: 390px;
          -webkit-transform: ${reversed ? 'scaleX(-1)' : 'none'};
          transform: ${reversed ? 'scaleX(-1)' : 'none'};

          @media (max-width: 1023px) {
            width: 60%;
            margin-left: 65px;
            -webkit-transform: none;
            transform: none;
          }

          @media (max-width: 639px) {
            width: 50%;
            -webkit-transform: none;
            transform: none;
          }


          // @media (max-width: 54em) {
          //   width: 190px;
          // }
        `}
      />
    </div>
  )
}


const Home = () => (
  <Layout page="home">
    <Header
      title={
        <h1 style={{fontWeight: '300'}}>
          {`Introducing`}
          <strong style={{fontWeight: '900'}}>{` CineSend Trailers`}</strong>
        </h1>
      }
      text="CineSend Trailers is an online portal for Canadian cinema exhibitors to download DCP trailers for current and upcoming releases."
      image={<HeaderImage />}
    />

    <h2 id="how-it-works" className="center" css={css`
      // color: #FD2A51;
      // background: linear-gradient(141.51deg, #E62E50 0%, #FF617E 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      margin-bottom: 3em;
      background: linear-gradient(to left, #E62E50 0%, #FF617E 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}>How it works</h2>

    <Feature
      title=""
      reverse
      text="Sign up for an account and browse available and upcoming trailers"
      icon="1"
      image={<Step1Image />} />

    <Line />

    <Feature
      title=""
      text="Connect your CSX if your cinema is already set up for electronic feature delivery"
      icon="2"
      image={<Step2Image />} />

    <Line reversed={true}/>

    <Feature
      title=""
      reverse
      text="Download trailers one-by-one, or subscribe to a title to automatically receive trailers as they become available"
      icon="3"
      image={<Step3Image />} />

    <Line />

    <Feature
      title=""
      text="Ingest the trailer DCPs onto your playback server just like normal"
      icon="4"
      image={<Step4Image />} />

    <Background tagName="section" type="gray-double" className="bg-cover bg-no-repeat bg-center px-2 md-px-3 px2 py5">
      <div className="bg-white p4 max-width-3 mx-auto" id="faq" css={css`
        border-radius: 2px;
        box-shadow: 0 9px 25px 0 rgba(56, 62, 67, 0.12);
      `}>
        <h2 id="how-it-works" className="center mb3" css={css`color: #FD2A51;`}>Frequently asked questions</h2>
        <div className='mb3'>
          <h4 className='mb1'>Does this replace my weekly trailer drive delivery?</h4>
          <p>
            We expect all studios to participate in CineSend Trailers by mid-2020 at which time you may decide to cancel your weekly trailer drive delivery.
          </p>
        </div>
        <div className='mb3'>
          <h4 className='mb1'>How much does it cost?</h4>
          <p>
            The service is completely free until December 31, 2020. Starting Jan 1, 2021, the service will cost $79/month per theatre location.
          </p>
        </div>
        <div>
          <h4 className='mb1'>What do I need to set up to use the service?</h4>
          <ol className='m0 p0' css={css`padding-left: 26px;`}>
            <li className='mb1'>If your cinema is already equipped with a CSX device, our team just needs to link up your CineSend Trailers account to your CSX device to allow download straight onto your server.</li>
            <li>If you do not yet have a CSX, you can still use CineSend Trailers and download the trailers you need as zip files. After the trailers are downloaded, you'll need to unzip each trailer, load them onto a USB key, and then ingest them onto your server from there.</li>
          </ol>
        </div>
      </div>
    </Background>


  </Layout>
)

Home.propTypes = {}

export default Home
