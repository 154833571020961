import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/step1.png/" }) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Img className="" alt="Step 1: Sign up for an account and browse available and upcoming trailers" fluid={data.file.childImageSharp.fluid} />
    )}
  />
)

export default Image
